import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TestimonialComponent } from '../testimonial/testimonial.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SharedModule } from '../shared/Shared.Module';

declare var $: any;

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [TestimonialComponent, SharedModule],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {
  SEOKey="One Drop Cab Service, One-Way Taxi to Chennai,  All Districts Cab,Reliable One-Way Drop Service, Affordable Cab Service, Punctual One-Way Transportation, Convenient Drop Service, Professional Cab Drivers, Stress-Free Travel, OneDropCabService";
  SEODescription= "Discover unparalleled convenience with our One Drop Cab Service, offering reliable and affordable one-way transportation across South India. Our professional drivers and well-maintained fleet ensure punctual and stress-free travel, making OneDropCab the preferred choice for your journey. Book now for a seamless and comfortable experience at the best rates. Your journey begins with us!";
  SEOTitle="One Drop Cab Service: Affordable One-Way Transportation - OneDropCab";   
  constructor(@Inject(PLATFORM_ID) private platformId: string, private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private doc){
    this.createLinkAndMetas(this.SEOTitle, this.SEOKey, this.SEODescription, true, false);
    if(isPlatformBrowser(this.platformId))
    {
      this.hideLoader();
    }
  }
  createLinkAndMetas(title: string, keywords: string, description: string, isCanonicalRequired: boolean, isLocation: boolean) {

    this.titleService.setTitle(title);
    var keys = "";
    var prekeys=keywords.split(",");
    if(isLocation)
    {
      var loc = this.doc.URL.split("/")[4];
      for(var i=0;i< prekeys.length; i++)
        {      
          keys+=prekeys[i]+" in "+loc+", "
        }
        keys = keys.substring(0,keys.length-1);
    }
    else{
      keys  = keywords
    }
    
    keys = keys.toLocaleLowerCase();
    this.metaService.updateTag(              
    {
      name: 'description', content: description
    })
    this.metaService.updateTag(              
    {
      name: 'keywords', content: keys
    })
    
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'og:image:alt', content:title });
    this.metaService.updateTag({ property: 'twitter:title', content: title });
    this.metaService.updateTag({ property: 'twitter:description', content: description });
    this.metaService.updateTag({ property: 'twitter:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'twitter:url', content:window.location.origin});
    this.metaService.updateTag({ property: 'twitter:site', content:'One Drop Cab'});
    if(isCanonicalRequired &&isPlatformBrowser(this.platformId))
    {
      const link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }
 }
 hideLoader(){

  setTimeout(() => {
      $('.preloader').fadeOut();
  }, 50);
}
}
