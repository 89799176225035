<div class="testimonial-area py-4">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mx-auto">
                <div class="site-heading text-center">
                    <span class="site-title-tagline"><i class="flaticon-drive"></i> Testimonials</span>
                    <h2 class="site-title text-white">What Our Client <span>Say's</span></h2>
                    <div class="heading-divider"></div>
                </div>
            </div>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme" >
            <div class="testimonial-single" *ngFor="let review of reviews">
                <div class="testimonial-content">
                    <div class="testimonial-author-img">
                        <i class="fa-solid fa-circle-user" style="  border-radius: 50%; font-size: 55px; color: chocolate; "></i>
                        <!-- <img loading="lazy" referrerpolicy="no-referrer" [src]="review.authorAttribution.photoUri" alt=""> -->
                    </div>
                    <div class="testimonial-author-info">
                        <h4>{{review.authorAttribution.displayName}}</h4>
                        <p>{{review.relativePublishTimeDescription}}</p>
                    </div>
                </div>
                <div class="testimonial-quote">
                    <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                    <p>
                        {{review.originalText.text}}
                    </p>
                </div>
                
                <div class="testimonial-rate" >
                    <i class="fas fa-star" *ngFor="let star of review.ratingArr"></i>
                    <!-- <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i> -->
                </div>
            </div>
            
        </div>
    </div>
</div>