import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SharedModule } from '../shared/Shared.Module';

declare var $: any;

declare const WOW: any;
@Component({
  selector: 'app-testimonial',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './testimonial.component.html',
  styleUrl: './testimonial.component.scss'
})
export class TestimonialComponent implements OnInit, AfterViewInit{
  reviews: any =[];
  constructor()
  {
    this.getReviews();  
  }
  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {
    
  }
  getRecentReviews(){
    fetch("https://mybusiness.googleapis.com/v4/locations/ChIJRzhz_jvzqzsRDfm1LsGk0Z4/reviews",
    {method:'GET'}
    ).then((response) => response.json())
    .then((resData) =>{ 
        this.reviews = resData.reviews
        let temp=[];
        for(var i =0;i< this.reviews.length;i++){
          if(this.reviews[i].rating >=3)
          {
           
            this.reviews[i].ratingArr=new Array(this.reviews[i].rating);
            temp.push(this.reviews[i]);
          }
          
        }
        this.reviews  = temp;
        setTimeout(() => {
          $('.testimonial-slider').owlCarousel({
            loop: true,
            margin: 30,
            nav: false,
            dots: true,
            autoplay: true,
            responsive: {
              0: {
                  items: 1
              },
              600: {
                  items: 2
              },
              1000: {
                  items: 3
              }
          }
          
        });
        this.hideLoader();
        }, 100);
        
    });
  }
  getReviews(){
    // this.getRecentReviews();
    fetch("https://places.googleapis.com/v1/places/ChIJRzhz_jvzqzsRDfm1LsGk0Z4?fields=id,displayName,reviews&key=AIzaSyCueSJROSfzAZKzFY5eCaVphykM3Yftc4I"
    ,{method:'GET'}
    ).then((response) => response.json())
    .then((resData) =>{ 
        this.reviews = resData.reviews
        let temp=[];
        if(!this.reviews)
          return;
        for(var i =0;i< this.reviews!.length;i++){
          if(this.reviews[i].rating >=3)
          {
           
            this.reviews[i].ratingArr=new Array(this.reviews[i].rating);
            temp.push(this.reviews[i]);
          }
          
        }
        this.reviews  = temp;
        setTimeout(() => {
          $('.testimonial-slider').owlCarousel({
            loop: true,
            margin: 30,
            nav: false,
            dots: false,
            autoplay: true,
            responsive: {
              0: {
                  items: 1
              },
              600: {
                  items: 2
              },
              1000: {
                  items: 3
              }
          }
          
        });
        this.hideLoader();
        }, 100);
        
    });
  }
  hideLoader(){

    setTimeout(() => {
        $('.preloader').fadeOut();
    }, 50);
  }
}
