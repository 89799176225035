<div class="container pd-50" style="padding: inherit; margin-top: 10vh ">
<header>
    <h1 class="site-title-tagline justify-content-start" style="width: fit-content;">About One Drop Cab</h1>
</header>
<section>
<div class="about-area" style="color: #323232;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-left wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                    <div class="about-img" >
                        <!-- <img loading="lazy" height="527" width="700" ngSrc="assets/img/about/01.avif" alt="about"> -->
                        <iframe width="527" height="300" src="https://www.youtube.com/embed/NtU7pcDFUZo" frameborder="0"></iframe>

                    </div>
                    <div class="about-experience">
                        <div class="about-experience-icon">
                            <img loading="lazy" src="assets/img/icon/taxi-booking.svg" alt="booking">
                        </div>
                        <b>30 Years Of <br> Quality Service</b>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-right wow fadeInRight" data-wow-delay=".25s">
                    <div class="site-heading mb-3">
                        <span class="site-title-tagline justify-content-start">
                            <i class="flaticon-drive"></i> About Us
                        </span>
                        <h2 class="site-title">
                            Welcome Aboard our   <span>Premier One Way Drop Taxi Service</span> in Tamil Nadu!
                        </h2>
                    </div>
                    <span class="about-list-wrapper" style="color: #323232; font-weight: 600;">
                        Embark on a journey of comfort and reliability with our esteemed One Way Drop Taxi service. Your safety is our top priority, and our team of experienced drivers is committed to ensuring a secure and enjoyable transportation experience for you.
                    </span>
                    <div class="about-list-wrapper">
                        <ul class="about-list list-unstyled">
                            <li>
                                <b>Reliable One Way Drop Taxi Service:</b> Count on us for punctual and dependable cab services, making sure you reach your destination on time, every time.
                            </li>
                            <li>
                                <b>Safety First:</b> Trust in the expertise of our skilled drivers who bring years of experience to ensure a smooth and pleasant journey for you.
                            </li>
                            <li>
                                <b>Experienced Drivers in One Drop Cab:</b>  Trust in the expertise of our skilled drivers who bring years of experience to ensure a smooth and pleasant journey for you.
                            </li>
                            <li>
                                 
                                <b>Your Journey Matters:</b> We understand the significance of your travel. Whether it's for business or leisure, your journey matters, and we are here to make it as convenient and enjoyable as possible.
                            </li>
                            <li>
                                <b>Our Features:</b> Outstation One way Drop Taxi Fare is very low, Easy Outstation Cab Booking,One Way Cab Booking in few clicks, One Way Call Taxi across Tamilnadu, Round Trip Taxi Service across India, Innova Car Rental on daily / monthly basis, Affordable One Way Drop Taxi Fare.
                            </li>
                        </ul>
                    </div>
                    <!-- <a href="about.html" class="theme-btn mt-4">Discover More<i
                            class="fas fa-arrow-right"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<!-- <section>
    <h2>Celebrating 30 Years of Excellence in Transportation</h2>
    <p>
        Welcome to One Drop Cab, a pioneering force in the transportation industry for over three decades. Since our establishment in 1994,
        we have been steadfast in providing unparalleled cab services, seamlessly connecting passengers to their destinations.
    </p>
    <p>
        Over the years, One Drop Cab has become synonymous with reliability, safety, and customer-centricity. Our commitment to excellence has propelled us to the forefront of the industry, making us the preferred choice for discerning travelers.
    </p>
</section> -->

<section>
    <h2>Our Enduring Values</h2>
    <p>
        At One Drop Cab, our success story is written with a set of enduring values that form the bedrock of our operations::
    </p>
    <ul >
        <li style="margin-bottom: 5px;"><b>Customer Satisfaction:</b> For us, your journey is not just a ride; it's an experience. We are dedicated to ensuring your comfort and satisfaction.</li>
        <li style="margin-bottom: 5px;"><b>Reliability:</b> Punctuality is our promise. Over the years, we've become a symbol of dependable transportation services.</li>
        <li style="margin-bottom: 5px;"><b>Experienced Drivers:</b> Our team of skilled and courteous drivers brings a wealth of experience, making your journey safe, pleasant, and memorable.</li>
        <li style="margin-bottom: 5px;"><b>Continuous Innovation:</b> Embracing the latest technologies and trends to enhance the overall travel experience for our passengers.</li>
    </ul>
</section>

<section>
    <h2>Why Choose One Drop Cab?</h2>
    <p>
        - <strong>Proven Track Record:</strong> With a legacy spanning over 30 years, trust us for reliable and time-tested cab services.
    </p>
    <p>
        - <strong>Community Integration:</strong> Proudly involved in community initiatives, we strive to give back to the neighborhoods we serve.
    </p>
    <p>
        - <strong>Transparent Pricing:</strong> Experience straightforward and competitive pricing, ensuring you get value for your money.
    </p>
</section>
<section>
    <h2>The Next Chapter Awaits</h2>
    <p>
        As we celebrate 30 years of excellence, we look forward to continuing our journey with you. Your trust has been our driving force, and we are excited about the chapters yet to unfold.
    </p>
</section>
<section>
     <!-- download area -->
     <div class="download-area pt-80 mb-120">
        <div class="container">
            <div class="download-wrapper">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="download-content">
                            <div class="site-heading mb-4">
                                <span class="site-title-tagline justify-content-start">
                                    <i class="flaticon-drive"></i> Get Our App
                                </span>
                                <h2 class="site-title mb-10">Download <span>Our One Drop Cab</span> App For Free</h2>
                                <p>
                                    Enjoy the freedom of seamless travel – download our taxi app for free! Available on iOS and Android, our user-friendly app lets you book rides, track your driver, and manage payments effortlessly. Your ride is just a tap away!
                                </p>
                            </div>
                            <div class="download-btn">
                                <a href="#">
                                    <i class="fab fa-google-play"></i>
                                    <div class="download-btn-content">
                                        <span>Get It On</span>
                                        <strong>Google Play</strong>
                                    </div>
                                </a>
                                <a href="#">
                                    <i class="fab fa-app-store"></i>
                                    <div class="download-btn-content">
                                        <span>Get It On</span>
                                        <strong>App Store</strong>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="download-img">
                    <img src="assets/img/download/01.png" alt="about" loading="lazy">
                </div>
            </div>
        </div>
    </div>
    <!-- download area end -->

</section>
</div>